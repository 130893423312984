/* eslint-disable import/no-unresolved */
/* eslint-disable no-nested-ternary */

import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { useText, useImg } from 'hooks';
import { FeaturesContext } from 'helpers/providers/FeaturesProvider';
import { MOBILE, TABLET, DESKTOP } from 'helpers/styles/media';
import { ButtonStyles } from '../UI/UI';

const Header = ({ prefix, scrollToCalc, primaryColor1, removeRangeSlider }) => {
  const features = useContext(FeaturesContext);
  const isCFD = features.includes('is_cfd');
  const logoAsText = features.includes('logo_as_text');
  const isCustomBg = features.includes('custom_bg');
  const isTest = features.includes('tst_version');
  const isTGOTest = features.includes('tst_version_tgo');

  const bgUrl = isCustomBg
    ? prefix
    : isTest
      ? '-tst-5'
      : isTGOTest
        ? '-tst-tgo-1'
        : '';

  return (
    <Wrapper
      isCustomBg={isCustomBg}
      bgUrl={bgUrl}
      isTest={isTest}
      isTGOTest={isTGOTest}
    >
      <Content isCustomBg={isCustomBg} isTest={isTest}>
        <Title isTest={isTest} isTGOTest={isTGOTest}>
          {useText('top__t1', true)}
        </Title>
        {logoAsText ? (
          <LogoText primaryColor1={primaryColor1}>
            {useText(`top__logo_text${prefix.replace('-', '_')}`, true)}
          </LogoText>
        ) : (
          <Logo isTGOTest={isTGOTest} isCFD={isCFD}>
            {isTest
              ? useImg('tst_logo_w.png', 'logo')
              : useImg(`logo${prefix}.png`, 'logo')}
            {isCFD && <CFD>{useText('cfd', true)}</CFD>}
          </Logo>
        )}
        <Title isTest={isTest} isTGOTest={isTGOTest}>
          {useText('top__t2', true)}
        </Title>
        {!removeRangeSlider && (
          <Button onClick={scrollToCalc}>{useText('top__btn', true)}</Button>
        )}
      </Content>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  background-size: auto 100%;
  background-position: center;
  background-repeat: no-repeat;
  padding: 20px 0;
  display: flex;
  align-items: center;

  @media ${DESKTOP} {
    background-image: ${({ bgUrl, theme: { imgUrl } }) =>
    imgUrl(`bg-d${bgUrl}.jpg`)};
    height: 470px;

    ${({ isCustomBg }) =>
    isCustomBg &&
      `
      height: 755px;
  `}

    ${({ isTest }) =>
    isTest &&
      `
      height: 820px;
  `}
   ${({ isTGOTest }) =>
    isTGOTest &&
     `
      height: 820px;
  `}
  }

  @media ${TABLET} {
    background-image: ${({ bgUrl, theme: { imgUrl } }) =>
    imgUrl(`bg-t${bgUrl}.jpg`)};
    height: 450px;

    ${({ isCustomBg }) =>
    isCustomBg &&
      `
      height: 565px;
  `}

    ${({ isTest }) =>
    isTest &&
      `
      height: 514px;
  `}

${({ isTGOTest }) =>
    isTGOTest &&
  `
      height: 514px;
  `}
  }

  @media ${MOBILE} {
    background-image: ${({ bgUrl, theme: { imgUrl } }) =>
    imgUrl(`bg-m${bgUrl}.jpg`)};
    height: 276px;

    ${({ isCustomBg }) =>
    isCustomBg &&
      `
      align-items: baseline;
      min-height: 767px;
      background-size: 767px auto;
      padding-bottom: 500px;
      background-position: bottom center;
  `}

    ${({ isTest }) =>
    isTest &&
      `
      min-height: 512px;

  `}

${({ isTGOTest }) =>
    isTGOTest &&
  `
      min-height: 512px;

  `}
  }
`;

const Content = styled.div`
  margin: 0 auto;
  display: flex;
  flex-flow: column;
  justify-content: center;

  @media ${DESKTOP} {
    max-width: 490px;
    transform: translateX(-25px);

    ${({ isTest }) =>
    isTest &&
      `
      transform: translateX(-20px) translateY(-73px);
  `}

    &:lang(de) {
      transform: translateX(-35px);
    }
  }

  @media ${TABLET} {
    max-width: 450px;
    transform: translateX(157px);
  }

  @media ${MOBILE} {
    max-width: 260px;
    transform: translateX(50px);

    ${({ isTest }) =>
    isTest &&
      `
      transform: translateX(50px) translateY(-20px);
  `}

    ${({ isCustomBg }) =>
    isCustomBg &&
      `
      transform: unset;
  `}
  }
`;

const Title = styled.div`
  color: #000;
  text-align: center;
  font-size: 38px;
  font-weight: 700;
  line-height: 120%;

  ${({ isTest }) =>
    isTest &&
    `
      color: #fff;
      text-shadow: 5px 2px 10px rgb(37, 36, 36);
  `}

${({ isTGOTest }) =>
    isTGOTest &&
  `
      color: #fff;
      text-shadow: 5px 2px 10px rgb(37, 36, 36);
  `}

  @media ${MOBILE} {
    font-size: 22px;
  }
`;
const LogoText = styled.div`
  color: ${({ primaryColor1 }) => primaryColor1 && primaryColor1};
  text-align: center;
  font-size: 55px;
  font-weight: 700;

  @media ${MOBILE} {
    font-size: 22px;
  }
`;

const CFD = styled.div`
  font-weight: 700;
  font-size: 30px;
  padding-left: 10px;
  color: #000;

  @media ${MOBILE} {
    font-size: 20px;
  }
`;

const Logo = styled.div`
  margin: 7px auto;
  max-width: 400px;

  ${({ isTGOTest }) =>
    isTGOTest &&
    `
    img{
      filter: drop-shadow(0 0 13px #fff);
    };
  `}

 

  ${({ isCFD }) =>
    isCFD &&
    `
    display: flex;
    align-items: center;

    `}

  @media ${MOBILE} {
    max-width: 210px;
  }

  img {
    max-height: 155px;

    @media ${MOBILE} {
      max-height: 75px;
    }
  }
`;

const Button = styled.div`
  ${ButtonStyles};

  max-width: 384px;
  padding: 14px 56px;
  margin-top: 30px;

  &:lang(de) {
    font-size: 19px;
  }

  @media ${MOBILE} {
    max-width: 259px;
    font-size: 15px;
    margin-top: 15px;
    padding: 10px 22px;

    &:lang(de) {
      font-size: 14px;
    }
  }
`;

Header.propTypes = {
  prefix: PropTypes.string,
  primaryColor1: PropTypes.string,
  scrollToCalc: PropTypes.func,
  removeRangeSlider: PropTypes.bool,
};

export default Header;
